<template>
  <layout-blank>
    <slot></slot>
  </layout-blank>
</template>

<script>
import LayoutBlank from '@/@core/layouts/variants/blank/LayoutBlank.vue';
import sharedStoreModule, { STORE_SHARED_MODULE_NAME } from '@/components/sharedStoreModule';
import store from '@store';
import { onUnmounted } from 'vue';

export default {
  components: {
    LayoutBlank
  },
  setup() {
    if (!store.hasModule(STORE_SHARED_MODULE_NAME)) {
      store.registerModule(STORE_SHARED_MODULE_NAME, sharedStoreModule)
    }

    onUnmounted(() => {
      if (store.hasModule(STORE_SHARED_MODULE_NAME))
        store.unregisterModule(STORE_SHARED_MODULE_NAME)
    })
  }
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/overrides.scss';
@import '@core/preset/preset/skins/bordered.scss';
@import '@core/preset/preset/skins/semi-dark.scss';
</style>
