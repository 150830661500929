<template>
  <v-menu offset-y left nudge-bottom="14" min-width="230" content-class="user-profile-menu-content">
    <template #activator="{ on, attrs }">
      <v-badge class="ms-4" bottom color="success" overlap offset-x="12" offset-y="12" dot>
        <v-avatar
          class="v-avatar-light-bg primary--text"
          v-bind="attrs"
          size="40px"
          color="primary"
          v-on="on"
        >
          <v-img v-if="userTokenData.avatar" :src="userTokenData.avatar" />
          <v-icon v-else color="primary" size="28">
            {{ mdiAccountOutline }}
          </v-icon>
        </v-avatar>
      </v-badge>
    </template>
    <v-list>
      <div class="pb-3 pt-2">
        <v-badge class="ms-4" bottom color="success" overlap offset-x="12" offset-y="12" dot>
          <v-avatar class="v-avatar-light-bg primary--text" size="40px" color="primary">
            <v-img v-if="userTokenData.avatar" :src="userTokenData.avatar" />
            <v-icon v-else color="primary" size="28">
              {{ mdiAccountOutline }}
            </v-icon>
          </v-avatar>
        </v-badge>
        <div class="d-inline-flex flex-column justify-center ms-3" style="vertical-align: middle">
          <span class="text--primary font-weight-semibold mb-n1">
            {{ userTokenData.userFullName }}
          </span>
          <small class="text--disabled text-capitalize">{{ userTokenData.roleName }}</small>
        </div>
      </div>
      <v-divider />

      <!-- Profile -->
      <v-list-item :to="{ name: 'apps-user-view', params: { id: 21 } }">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ mdiAccountOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Profile</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Email -->
      <v-list-item :to="{ name: 'apps-email' }">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ mdiEmailOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Inbox</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Chat -->
      <v-list-item :to="{ name: 'apps-chat' }">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ mdiChatOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Chat</v-list-item-title>
        </v-list-item-content>

        <v-list-item-action>
          <v-badge inline color="error" content="2" />
        </v-list-item-action>
      </v-list-item>

      <v-divider class="my-2" />

      <!-- Settings -->
      <v-list-item :to="{ name: 'page-account-settings' }">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ mdiCogOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Settings</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Pricing -->
      <v-list-item :to="{ name: 'page-pricing' }">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ mdiCurrencyUsd }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Pricing</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- FAQ -->
      <v-list-item :to="{ name: 'page-faq' }">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ mdiHelpCircleOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>FAQ</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="my-2" />

      <!-- Logout -->
      <v-list-item @click="logoutUser">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $tc('Logout') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script setup>
import { useRouter } from '@core/utils'

import {
  mdiAccountOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiEmailOutline,
  mdiHelpCircleOutline,
  mdiLogoutVariant
} from '@mdi/js'

import store, { mapGetters } from '@store'

// <!-- #endregion -->

// const vm = getCurrentInstance().proxy
const { router } = useRouter()
const { userTokenData } = mapGetters(['user/tokenData'])

const logoutUser = () => {
  store.dispatch('user/setLogout')

  router.push({ name: 'auth-login' })
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
