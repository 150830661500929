import { MODULES_ACTION, MODULES_NAME } from '@/config/roles'

import { mdiChartBox } from '@mdi/js'

export default [
  {
    subheader: 'Dashboards'
  },
  {
    icon: mdiChartBox,
    title: 'Ventas',
    to: 'dashboard-sales',
    action: MODULES_ACTION.READ,
    subject: MODULES_NAME.DASHBOARD_SALES
  },
  {
    icon: mdiChartBox,
    title: 'Suscripciones',
    to: 'dashboard-subscriptions',
    action: MODULES_ACTION.READ,
    subject: MODULES_NAME.DASHBOARD_SUBSCRIPTIONS
  }
]
