var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{staticClass:"content-layout vertical-nav",class:[
    { 'nav-drawer-mini': _vm.menuIsVerticalNavMini },
    { 'content-full': _vm.appContentWidth === 'full' }
  ]},[(!_vm.menuIsMenuHidden)?_c('v-navigation-drawer',{ref:"layoutDrawer",staticClass:"app-navigation-menu",attrs:{"expand-on-hover":_vm.menuIsVerticalNavMini,"right":_vm.$vuetify.rtl,"app":"","mini-variant-width":"68","width":"260"},model:{value:(_vm.isVerticalNavMenuActive),callback:function ($$v) {_vm.isVerticalNavMenuActive=$$v},expression:"isVerticalNavMenuActive"}},[_c('vertical-nav-menu',{attrs:{"nav-menu-items":_vm.navMenuItems},on:{"close-nav-menu":function($event){_vm.isVerticalNavMenuActive = false}}})],1):_vm._e(),(_vm.appBarType !== 'hidden')?_c('v-app-bar',{ref:"refAppBar",staticClass:"mx-auto app-bar-static",class:[
      { 'px-5 app-bar-shinked': _vm.appBarType === 'fixed' && _vm.scrollY },
      { 'bg-blur': _vm.appBarIsBlurred && _vm.appBarType === 'fixed' && _vm.scrollY }
    ],attrs:{"absolute":_vm.appBarType === 'static',"elevate-on-scroll":_vm.appBarType !== 'static',"elevation":_vm.appBarType !== 'static' ? (_vm.$vuetify.theme.isDark ? 4 : 3) : 0,"app":"","flat":""}},[_vm._t("navbar",null,{"isVerticalNavMenuActive":_vm.isVerticalNavMenuActive,"toggleVerticalNavMenuActive":_vm.toggleVerticalNavMenuActive})],2):_vm._e(),_vm._t("v-app-content"),_c('v-main',{staticClass:"grey lighten-3"},[_c('app-content-container',[_vm._t("default")],2)],1),_c('v-overlay',{staticClass:"content-overlay",attrs:{"value":_vm.shallContentShowOverlay,"z-index":"4","absolute":""}}),(_vm.footerType !== 'hidden')?_c('v-footer',{class:{ 'mx-auto': _vm.appContentWidth !== 'full' },attrs:{"absolute":_vm.footerType === 'static',"color":_vm.footerType === 'static' ? 'transparent' : null,"app":"","inset":"","padless":""}},[_c('div',{staticClass:"py-4 w-full",class:{ 'px-5': _vm.footerType === 'fixed' }},[_vm._t("footer")],2)]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }