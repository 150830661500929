import { MODULES_ACTION, MODULES_NAME } from '@/config/roles'

const apps = [
  // #region  orders ...
  {
    path: '/apps/orders/create',
    name: 'apps-orders-create',
    component: () => import('@/views/apps/orders/order-save/OrderSave.vue'),
    meta: {
      layout: 'content',
      requireIsLogged: true,
      action: MODULES_ACTION.CREATE,
      subject: MODULES_NAME.ORDERS
    }
  },
  {
    path: '/apps/orders/:id/edit',
    name: 'apps-orders-edit',
    component: () => import('@/views/apps/orders/order-save/OrderSave.vue'),
    meta: {
      layout: 'content',
      requireIsLogged: true,
      action: MODULES_ACTION.UPDATE,
      subject: MODULES_NAME.ORDERS
    }
  },
  {
    path: '/apps/orders/list',
    name: 'apps-orders-list',
    component: () => import('@/views/apps/orders/order-list/OrderList.vue'),
    meta: {
      layout: 'content',
      requireIsLogged: true,
      action: MODULES_ACTION.READ,
      subject: MODULES_NAME.ORDERS
    }
  },
  {
    path: '/apps/orders/:id/payment',
    name: 'apps-orders-payment',
    component: () => import('@/views/apps/orders/order-payment/OrderPayment.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      requireIsLogged: false
    }
  },
  {
    path: '/apps/orders/:reference/payment/finalized',
    name: 'apps-orders-payment-finalized',
    component: () => import('@/views/apps/orders/order-payment-result/OrderPaymentResult.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      requireIsLogged: false
    }
  },
  // #endregion
  // #region  users ...
  {
    path: '/apps/user/list',
    name: 'apps-user-list',
    component: () => import('@/views/apps/user/user-list/UserList.vue'),
    meta: {
      layout: 'content',
      requireIsLogged: true,
      action: MODULES_ACTION.READ,
      subject: MODULES_NAME.USERS_TENANT
    }
  },
  {
    path: '/apps/user/view/:id',
    name: 'apps-user-view',
    component: () => import('@/views/apps/user/user-view/UserView.vue'),
    meta: {
      layout: 'content',
      requireIsLogged: true,
      action: MODULES_ACTION.DETAIL,
      subject: MODULES_NAME.USERS_TENANT
    }
  },
  // #endregion
  // #region  products ...
  {
    path: '/products',
    name: 'product-list',
    component: () => import('@/views/products/ProductsList.vue'),
    meta: {
      layout: 'content',
      requireIsLogged: true,
      action: MODULES_ACTION.READ,
      subject: MODULES_NAME.PRODUCTS
    }
  },
  // #endregion
  // #region  companies ...
  {
    path: '/companies',
    name: 'company-list',
    component: () => import('@/views/companies/company-list/CompanyList.vue'),
    meta: {
      layout: 'content',
      requireIsLogged: true,
      action: MODULES_ACTION.READ,
      subject: MODULES_NAME.COMPANIES
    }
  },
  {
    path: '/companies/:id',
    name: 'company-view',
    component: () => import('@/views/companies/company-view/CompanyView.vue'),
    meta: {
      layout: 'content',
      requireIsLogged: true,
      action: MODULES_ACTION.DETAIL,
      subject: MODULES_NAME.COMPANIES
    }
  },
  // #endregion
  // #region  users-hub ...
  {
    path: '/users-hub',
    name: 'users-hub-list',
    component: () => import('@/views/users-hub/users-hub-list/UsersHubList.vue'),
    meta: {
      layout: 'content',
      requireIsLogged: true,
      action: MODULES_ACTION.READ,
      subject: MODULES_NAME.USERS_HUB
    }
  },
  {
    path: '/users-tenant',
    name: 'users-tenant-list',
    component: () => import('@/views/users-tenant/users-tenant-list/UsersTenantList.vue'),
    meta: {
      layout: 'content',
      requireIsLogged: true,
      action: MODULES_ACTION.READ,
      subject: MODULES_NAME.USERS_TENANT
    }
  },
  // #region  signup ...
  {
    path: '/signup/register/site',
    name: 'signup-register-site',
    component: () => import('@/views/apps/signup/RegisterSite.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      requireIsLogged: false
    }
  },
  {
    path: '/signup/register/site1',
    name: 'signup-register-site1',
    component: () => import('@/views/pages/authentication/Register-v1.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      requireIsLogged: false
    }
  },
  {
    path: '/signup/register/crm',
    name: 'signup-register-crm',
    component: () => import('@/views/apps/signup/RegisterCrm.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      requireIsLogged: false
    }
  }
  // #endregion
]

export default apps
