import { MODULES_NAME, MODULES_ACTION } from '@/config/roles'

const dashboard = [
  {
    path: '/dashboards/subscriptions',
    name: 'dashboard-subscriptions',
    component: () => import('@/views/dashboards/subscriptions/Subscriptions.vue'),
    meta: {
      layout: 'content',
      requireIsLogged: true,
      action: MODULES_ACTION.READ,
      subject: MODULES_NAME.DASHBOARD_SUBSCRIPTIONS,
    },
  },
  {
    path: '/dashboards/sales',
    name: 'dashboard-sales',
    component: () => import('@/views/dashboards/sales/Sales.vue'),
    meta: {
      layout: 'content',
      requireIsLogged: true,
      action: MODULES_ACTION.READ,
      subject: MODULES_NAME.DASHBOARD_SALES,
    },
  },
  {
    path: '/dashboards/crm',
    name: 'dashboard-crm',
    component: () => import('@/views/dashboards/crm/Crm.vue'),
    meta: {
      layout: 'content',
      requireIsLogged: true,
      // action: MODULES_ACTION.READ,
      // subject: MODULES_NAME.DASHBOARD_CRM,
    },
  },
]

export default dashboard
