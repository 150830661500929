import '@/styles/styles.scss'
import 'sweetalert2/dist/sweetalert2.min.css'
import './registerServiceWorker'
import './vee-validate'

import App from './App.vue'
import Vue from 'vue'
import VueSweetalert2 from 'vue-sweetalert2'
import { ability } from '@config/ability'
import { i18n } from '@/plugins/i18n'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

// #region  vendors ...
// window.ability = ability;
Vue.use(VueSweetalert2)
// #endregion
Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  vuetify,
  ability,
  render: h => h(App)
}).$mount('#app')
