import { format, parseISO } from 'date-fns'

const culture = 'es-CO'
const currency = 'COP'
const timeZone = 'America/Bogota'

export function toPascalCase (value) {
  return `${value}`
    .toLowerCase()
    // eslint-disable-next-line prefer-regex-literals
    .replace(new RegExp(/[-_]+/, 'g'), ' ')
    // eslint-disable-next-line prefer-regex-literals
    .replace(new RegExp(/[^\w\s]/, 'g'), '')
    .replace(
      // eslint-disable-next-line prefer-regex-literals
      new RegExp(/\s+(.)(\w*)/, 'g'),
      ($1, $2, $3) => `${$2.toUpperCase() + $3}`
    )
    // eslint-disable-next-line prefer-regex-literals
    .replace(new RegExp(/\w/), s => s.toUpperCase())
}

export function toCapitalizeCase (value) {
  return `${value}`.charAt(0).toUpperCase() + `${value}`.slice(1)
}

export function latinize (value) {
  return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()
}
export const formatToDate = value => {
  if (value && !value.includes('0001')) {
    return format(parseISO(value), 'd/MM/yyyy')
  }

  return ''
}
export const formatToDateTime = value => {
  if (value && !value.includes('0001')) {
    return format(parseISO(value), 'd/MM/yyyy,  h:mm:s aa')
  }

  return ''
}
export const formatYesNo = value => (value ? 'Si' : 'No')
export const formatEnabled = value => (value ? 'Activa' : 'Desactivada')
export const formatToTime = value => {
  if (value && value !== '0001-01-01T00:00:00Z') {
    const date = new Date(value)

    return date.toLocaleTimeString(culture, {
      timeZoneId: timeZone
    })
  }

  return ''
}
export const formatToDecimal = value => {
  if (value) {
    return value.toLocaleString(culture)
  }

  return ''
}
export const capitalize = str => {
  if (str) {
    const lower = str.toLowerCase()

    return str.charAt(0).toUpperCase() + lower.slice(1)
  }

  return ''
}
export const formatToCurrency = value => {
  if (value) {
    return parseFloat(value).toLocaleString(culture, {
      currencyDisplay: 'symbol',
      style: 'currency',
      currency,
      minimumFractionDigits: 0
    })
  }

  return (0).toLocaleString(culture, {
    currencyDisplay: 'symbol',
    style: 'currency',
    currency,
    minimumFractionDigits: 2
  })
}
export const formatToPercent = value => {
  if (value) {
    return (parseFloat(value) / 100).toLocaleString(culture, {
      style: 'percent',
      minimumFractionDigits: 2
    })
  }

  return (0).toLocaleString(culture, {
    style: 'percent',
    minimumFractionDigits: 2
  })
}

export const getInitials = fullName =>

  fullName
    .split(' ')
    .map(n => n[0])
    .join('')

export const companyType = value => {
  if (value) {
    return value
  }

  return ''
}

export * from './gravatar.js'
