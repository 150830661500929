// eslint-disable-next-line camelcase

import gravatar from 'gravatar.js'
import jwtDecode from 'jwt-decode'

export default {
  namespaced: true,
  state () {
    return {
      isAuthenticated: localStorage.getItem('isAuthenticated') || false,
      userEmail: localStorage.getItem('accessToken')
        ? jwtDecode(localStorage.getItem('accessToken')).userEmail
        : '',
      tokenData: localStorage.getItem('accessToken')
        ? jwtDecode(localStorage.getItem('accessToken'))
        : {},
      userData: JSON.parse(localStorage.getItem('userData') || '{}'),
      sellers: JSON.parse(localStorage.getItem('hub::sellers') || '[]')
    }
  },
  getters: {
    isAuthenticated (state) {
      return state.tokenData && Date.now() <= state.tokenData.exp * 1000 && state.isAuthenticated
    },
    tokenData (state) {
      return {
        ...state.tokenData,
        roleName: state.userData.roleName,
        avatar: state && state.tokenData && state.tokenData.userEmail ? gravatar.url(state.tokenData.userEmail, { defaultIcon: 'robohash', size: 200 }) : '',
        userFullName: `${state.tokenData.userFirstName} ${state.tokenData.userLastName}`
      }
    },
    userData (state) {
      return state.userData
    },
    authenticated (state) {
      return {
        tokenData: state.tokenData,
        userData: state.userData,
        isAuthenticated: state.isAuthenticated
      }
    },
    sellers (state) {
      return state.sellers
    }
  },
  mutations: {
    SET_IS_AUTHENTICATED (state, status) {
      state.isAuthenticated = status
    },
    SET_AUTHENTICATED (state, params) {
      const { user, token } = params

      const tokenData = jwtDecode(token)

      // user.avatar = gravatar.url(tokenData.userEmail, { defaultIcon: 'robohash', size: 200 })

      localStorage.setItem('accessToken', token)
      localStorage.setItem('userData', JSON.stringify(user))
      localStorage.setItem('isAuthenticated', params.status)

      state.tokenData = tokenData
      state.userData = user
      state.isAuthenticated = params.status
    },
    SET_LOGOUT (state) {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('userData')
      localStorage.setItem('isAuthenticated', false)

      state.tokenData = {}
      state.userData = {}
      state.isAuthenticated = false
    },
    SET_SELLERS (state, value) {
      // user.avatar = gravatar.url(tokenData.userEmail, { defaultIcon: 'robohash', size: 200 })
      localStorage.setItem('hub::sellers', JSON.stringify(value))
      state.sellers = value
    }
  },
  actions: {
    setIsAuthenticated ({ commit }, status) {
      commit('SET_IS_AUTHENTICATED', status)
    },
    setAuthenticated ({ commit }, options) {
      commit('SET_AUTHENTICATED', options)
    },
    setLogout ({ commit }) {
      commit('SET_LOGOUT')
    },
    setSellers ({ commit }, value) {
      commit('SET_SELLERS', value)
    }
  }
}
