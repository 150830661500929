import { i18n } from '@/plugins/i18n/index'
import Vue from 'vue'

export default {
  info (message) {
    Vue.swal(`${i18n.tc('Info')}!`, message, 'info')
  },
  validateForm (message) {
    Vue.swal(`${i18n.tc('ValidateForm')}!`, message || i18n.tc('ValidateFormHelper'), 'info')
  },
  successCreated (message) {
    Vue.swal(`${i18n.tc('Ok')}!`, message || i18n.tc('SuccessCreated'), 'success')
  },
  successSaved (message) {
    Vue.swal(`${i18n.tc('Ok')}!`, message || i18n.tc('SuccessSaved'), 'success')
  },
  successDeleted (message) {
    Vue.swal(`${i18n.tc('Ok')}!`, message || i18n.tc('SuccessDeleted'), 'success')
  },
  success (message) {
    Vue.swal(`${i18n.tc('Ok')}!`, message || i18n.tc('Success'), 'success')
  },
  error (message) {
    Vue.swal(`${i18n.tc('Opps')}!`, message || i18n.tc('OppsHelper'), 'error')
  }
}
