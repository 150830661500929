import { email, max, required } from "vee-validate/dist/rules";
import { extend } from "vee-validate";
import { i18n } from '@/plugins/i18n/index'

extend("required", {
  ...required,
  message: i18n.tc('FieldIsRequired')
});

extend("max", {
  ...max,
  message: i18n.tc('FieldLessThan')
});

extend("email", {
  ...email,
  message: i18n.tc('FieldIsEmailInvalid')
});
