import preset from '@/@core/preset/preset'
import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import es from 'vuetify/lib/locale/es'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

Vuetify.config.noDataText = 'No se han encontrado resultados'
Vuetify.config.dataIterator = {
  noResultsText: 'La consulta no arrojo resultados'
}

const vuetify = new Vuetify({
  preset,
  icons: {
    iconfont: 'mdiSvg'
  },
  theme: {
    themes: {
      light: {
        amberLighten2: colors.amber.lighten2,
        amberLighten4: colors.amber.lighten4,
        blueGreyLighten4: colors.blueGrey.lighten4,
        blueLighten4: colors.blue.lighten4,
        brownLighten4: colors.brown.lighten4,
        cyan: colors.cyan.base,
        deepOrangeLighten1: colors.deepOrange.lighten1,
        deepPurpleLighten4: colors.deepPurple.lighten4,
        greenLighten4: colors.green.lighten4,
        greyLighten4: colors.grey.lighten4,
        indigo: colors.red.indigo,
        indigoLighten4: colors.indigo.lighten4,
        lightBlueLighten4: colors.lightBlue.lighten4,
        lightGreenLighten4: colors.lightGreen.lighten4,
        limeDarken4: colors.lime.darken4,
        limeLighten4: colors.lime.lighten4,
        pinkLighten4: colors.pink.lighten4,
        purpleLighten4: colors.purple.lighten4,
        redAccent4: colors.red.accent4,
        redDarken1: colors.red.darken1,
        redLighten4: colors.red.lighten4,
        teal: colors.teal.base,
        tealDarken1: colors.teal.darken1,
        tealLighten4: colors.teal.lighten4
      }
    },
    options: {
      customProperties: true,
      variations: false
    }
  },
  lang: {
    locales: { es },
    current: 'es'
  }
})

export default vuetify
