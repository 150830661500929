import { MODULES_ACTION, MODULES_NAME } from '@/config/roles'
import { mdiAccountGroupOutline, mdiAccountNetworkOutline, mdiBadgeAccountOutline, mdiFileDocumentOutline, mdiInvoiceListOutline, mdiReceiptTextPlus } from '@mdi/js'

export default [
  {
    icon: mdiAccountGroupOutline,
    title: 'Clientes',
    to: 'companies',
    action: MODULES_ACTION.READ,
    subject: MODULES_NAME.COMPANIES
  },
  {
    icon: mdiInvoiceListOutline,
    title: 'Órdenes',
    to: 'apps-orders-list',
    action: MODULES_ACTION.READ,
    subject: MODULES_NAME.ORDERS
  },
  {
    icon: mdiReceiptTextPlus,
    title: 'Crear órden',
    to: {
      name: 'apps-orders-create'
    },
    action: MODULES_ACTION.CREATE,
    subject: MODULES_NAME.ORDERS
  },
  {
    icon: mdiFileDocumentOutline,
    title: 'Productos',
    to: 'products',
    action: MODULES_ACTION.READ,
    subject: MODULES_NAME.PRODUCTS
    // translateIndex: 2,
  },
  {
    icon: mdiBadgeAccountOutline,
    title: 'Usuarios Hub',
    to: 'users-hub-list',
    action: MODULES_ACTION.READ,
    subject: MODULES_NAME.USERS_HUB
    // translateIndex: 2,
  },
  {
    icon: mdiAccountNetworkOutline,
    title: 'Usuarios Empresas',
    to: 'users-tenant-list',
    action: MODULES_ACTION.READ,
    subject: MODULES_NAME.USERS_TENANT
    // translateIndex: 2,
  }
]
