import { render, staticRenderFns } from "./LayoutContentVerticalNav.vue?vue&type=template&id=b1a6bf94&scoped=true"
import script from "./LayoutContentVerticalNav.vue?vue&type=script&setup=true&lang=js"
export * from "./LayoutContentVerticalNav.vue?vue&type=script&setup=true&lang=js"
import style0 from "./LayoutContentVerticalNav.vue?vue&type=style&index=0&id=b1a6bf94&prod&lang=scss&scoped=true"
import style1 from "./LayoutContentVerticalNav.vue?vue&type=style&index=1&id=b1a6bf94&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b1a6bf94",
  null
  
)

export default component.exports