import themeConfig from '@themeConfig'

import './overrides.scss'

// Skins
import './skins/bordered.scss'
import './skins/semi-dark.scss'

export default {
  theme: {
    themes: {
      ...themeConfig.themes,
    },
    dark:
      localStorage.getItem('materio-active-theme') === null
        ? themeConfig.app.isDark
        : localStorage.getItem('materio-active-theme') === 'dark',
  },
  rtl: themeConfig.app.isRtl,
}
