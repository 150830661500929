import { createMongoAbility, defineAbility } from '@casl/ability'
import {
  defineAbilitiesForAdmin,
  defineAbilitiesForDirectorSales,
  defineAbilitiesForLeaderSupport,
  defineAbilitiesForRoot,
  defineAbilitiesForSales,
  defineAbilitiesForSupport
} from './roles'

import Vue from 'vue'
import { abilitiesPlugin } from '@casl/vue'
import { i18n } from '@/plugins/i18n/index'
import { mapGetters } from '@store'

const { userTokenData } = mapGetters(['user/tokenData'])

let USER_ROLES = {
  SUPER_ADMIN: 1,
  ADMIN: 2,
  SUPPORT: 3,
  SELLER: 4,
  DIRECTOR_SALES: 5,
  LEADER_SUPPORT: 6,
}


// export const ability = defineAbility(()=>{})

export function defineAbilityFor(roleId) {
  const DEFAULT_ABILITIES = new createMongoAbility() //defaults to no permissions

  let abilityRules
  switch (+roleId) {
    case USER_ROLES.SUPER_ADMIN:
      abilityRules = defineAbilitiesForRoot()
      break
    case USER_ROLES.ADMIN:
      abilityRules = defineAbilitiesForAdmin()
      break
    case USER_ROLES.SUPPORT:
      abilityRules = defineAbilitiesForSupport()
      break
    case USER_ROLES.SELLER:
      abilityRules = defineAbilitiesForSales()
      break
    case USER_ROLES.DIRECTOR_SALES:
      abilityRules = defineAbilitiesForDirectorSales()
      break
    case USER_ROLES.LEADER_SUPPORT:
      abilityRules = defineAbilitiesForLeaderSupport()
      break
    default:
      abilityRules = DEFAULT_ABILITIES
      break
  }

  return abilityRules;

  // ability.update(abilityRules)/

  // return abilityRules
}

export const ability = createMongoAbility(defineAbilityFor(userTokenData.value.roleId))

Vue.use(abilitiesPlugin, ability, {
  useGlobalProperties: true
})

export const validateHasAccess = (action, subject, message) =>{
  if(!ability.can(action, subject)){
    Vue.swal(`${i18n.tc('Unauthorized')}!`, message || i18n.tc('UnauthorizedHelper'), 'error')
    return false
  }

  return true
}
