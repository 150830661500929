import axios from '@axios'

export default {
  actions: {
    fetchAttachments (ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`attachments/signed?url=${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCompany (ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`companies/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCompanyByKey (ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('companies/findByCompanyKey', {
            params: {
              companyKey: payload
            }
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    findCompanies (ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('companies/find', {
            params: {
              query: payload.query,
              itemsPerPage: payload.itemsPerPage,
              page: payload.page
            }
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    findPartners (ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('companies/partners', {
            params: {
              query: payload.query,
              itemsPerPage: payload.itemsPerPage,
              page: payload.page
            }
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    findCompaniesByProduct (ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`companies/findByProduct/${payload.productId}`, {
            params: {
              query: payload.query,
              itemsPerPage: payload.itemsPerPage,
              page: payload.page
            }
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // #region  feature categories ...
    fetchFeatureCategories (ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('feature-categories')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // #endregion

    fetchSubscriptionByCompanyByProduct (ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`companies/${payload.companyId}/subscription/${payload.productId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchAddOn (ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`add-ons/${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAddOns (ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`products/${payload.productId}/addOns`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAddOnsByProduct (ctx, producId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`products/${producId}/addOns`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAddOnsByProductIsRecurring (ctx, producId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`products/${producId}/addOnsIsRecurring`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchFeaturesByPlan (ctx, planId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`plans/${planId}/features`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchFeaturesByProduct (ctx, producId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`products/${producId}/features`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchItems () {
      return new Promise((resolve, reject) => {
        axios
          .get('items/enabled')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchPlansByProduct (ctx, producId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`products/${producId}/plans`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchListPlansByProduct (ctx, payload) {
      payload.page = payload.page || 0
      payload.limit = payload.limit || 0

      return new Promise((resolve, reject) => {
        axios
          .get(`products/${payload.productId}/plans/list`, {
            params: {
              limit: payload.limit,
              offset: (payload.page - 1) * payload.limit,
              onlyEnabled: payload.onlyEnabled
            }
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchProduct (productId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`products/${productId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchProducts () {
      return new Promise((resolve, reject) => {
        axios
          .get('products')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchSellers () {
      return new Promise((resolve, reject) => {
        axios
          .get('users/sellers')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchTaxes () {
      return new Promise((resolve, reject) => {
        axios
          .get('taxes')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchPaymentBanksEPayco (ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('payments-/epaycobanks')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchIp (ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('https://api.ipify.org?format=json', {
            headers: {
              'X-NoAuthorization': true
            }
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchUrlAttachments (ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`attachments/signed?url=${payload}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    }
  },
  getters: {},
  mutations: {},
  namespaced: true,
  state: {}
}
export const STORE_SHARED_MODULE_NAME = 'shared'
